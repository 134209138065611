const sentences = [
    'Als je niet najaagt wat je wilt, zul je het nooit hebben',
    'Als je het niet vraagt, is het antwoord altijd nee',
    'Als je nooit een stap vooruit zet, zul je altijd op dezelfde plek blijven staan',
    'Wat je ook verliest, je wint altijd als je voor jezelf kiest',
    'Kijk niet om, je gaat die kant niet op',
    'Maak het doel niet minder, vergroot de inspanning',
    'Succes is niet het einde, falen is niet fataal, het is de moed om door te gaan die telt',
    'Succes gaat van mislukking naar mislukking, zonder verlies van enthousiasme',
    'Hoop niet op dat het makkelijker wordt, maar maak jezelf beter',
    'Het leven is net ganzenborden. Je kunt steeds weer opnieuw beginnen, tenzij je in de put blijft zitten',
    'Het is beter om een kaars aan te steken, dan om de duisternis te vervloeken',
];

export default function getRandomSentence() {
    const count = sentences.length;
    const randomIndex = Math.floor(Math.random() * count);

    return sentences[randomIndex];
}
