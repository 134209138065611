import 'antd/dist/antd.css';

import {
    Button,
    Card,
    Col,
    DatePicker,
    Form,
    Input,
    Layout,
    Row,
    Spin,
    notification,
} from 'antd';

import { FirestoreCollection } from '@react-firebase/firestore';
import { SmileTwoTone } from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';
import { addDate } from './store';
import getRandomSentence from './motivational';
import moment from 'moment';
import { useState } from 'react';

const { Content, Footer } = Layout;

function App() {
    return (
        <div className="app">
            <Layout>
                <Content style={{ height: '100vh', padding: '24px 50px' }}>
                    <FirestoreCollection
                        path="/dates/"
                        orderBy={[{ field: 'date', type: 'asc' }]}
                    >
                        {(d) => {
                            return <List {...d}></List>;
                        }}
                    </FirestoreCollection>
                </Content>

                <Footer
                    style={{
                        position: 'fixed',
                        bottom: 0,
                        left: 0,
                        width: '100%',
                    }}
                >
                    <FormElement></FormElement>
                </Footer>
            </Layout>
        </div>
    );
}

interface Model {
    name: string;
    date: any;
    description: string;
    timestamp: string;
}

interface ListProps {
    path: string;
    value: Model[];
    ids: string[];
    isLoading: boolean;
}

function List(props: ListProps) {
    if (props.isLoading) {
        return (
            <Spin
                style={{
                    position: 'fixed',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%)',
                }}
                tip={'laden'}
            ></Spin>
        );
    }

    return (
        <Row gutter={[24, 24]}>
            {props.value.map((item) => {
                const date = moment(item.date);
                const now = moment.now();

                if (date.isBefore(now)) {
                    return null;
                }

                return (
                    <Col key={item.timestamp} xs={24} sm={12} lg={6}>
                        <Card title={item.name}>
                            <p>{date.format('DD-MM-Y')}</p>
                            <p>{item.description}</p>
                        </Card>
                    </Col>
                );
            })}
        </Row>
    );
}

const validateMessages = {
    required: '${label} is verplicht!',
    types: {
        email: '${label} is not a valid email!',
        number: '${label} is not a valid number!',
    },
    number: {
        range: '${label} must be between ${min} and ${max}',
    },
};

function FormElement() {
    const [name, setName] = useState('');
    const [date, setDate] = useState('');
    const [description, setDescription] = useState('');

    const onSubmit = async (values: any) => {
        const result = await addDate(name, date, description, moment.now());

        notification.open({
            message: 'Lekker pik!',
            description: getRandomSentence(),
            icon: <SmileTwoTone />,
        });
    };

    return (
        <Form
            layout="vertical"
            onFinish={onSubmit}
            name="add-date"
            validateMessages={validateMessages}
        >
            <Row gutter={10} align="bottom">
                <Col xs={24}>
                    <Form.Item
                        name={['description']}
                        label="Omschrijving"
                        rules={[{ required: true }]}
                    >
                        <TextArea
                            onChange={(e) => setDescription(e.target.value)}
                            name="description"
                        ></TextArea>
                    </Form.Item>
                </Col>
                <Col xs={12} md={10} lg={14}>
                    <Form.Item
                        name={['user', 'name']}
                        label="Naam"
                        rules={[{ required: true }]}
                    >
                        <Input
                            placeholder="Naam"
                            name="name"
                            type="text"
                            onChange={(e) => setName(e.target.value)}
                            size="large"
                        ></Input>
                    </Form.Item>
                </Col>
                <Col xs={12} md={8} lg={6}>
                    <Form.Item
                        name={['Date', 'name']}
                        label="Datum"
                        rules={[{ required: true }]}
                        style={{ width: '100%' }}
                    >
                        <DatePicker
                            onChange={(date, string) => setDate(string)}
                            placeholder="Selecteer datum"
                            name="date"
                            id=""
                            size="large"
                            style={{ width: '100%' }}
                        ></DatePicker>
                    </Form.Item>
                </Col>
                <Col xs={24} md={6} lg={4}>
                    <Form.Item>
                        <Button
                            style={{ width: '100%' }}
                            size="large"
                            type="primary"
                            htmlType="submit"
                        >
                            Dan kan ik!
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
}

export default App;
