import 'firebase/firestore';

import firebase from 'firebase/app';

const config = {
    apiKey: 'AIzaSyB0h65ccKKZOKq1LfyPjSCCyX9RVJJb0AM',
    authDomain: 'wanneerkunjewel.firebaseapp.com',
    databaseURL:
        'https://wanneerkunjewel-default-rtdb.europe-west1.firebasedatabase.app',
    projectId: 'wanneerkunjewel',
    storageBucket: 'wanneerkunjewel.appspot.com',
    messagingSenderId: '756274544926',
    appId: '1:756274544926:web:1756baa2e50880843d490f',
};

firebase.initializeApp(config);
const db = firebase.firestore();

export function addDate(
    name: string,
    date: string,
    description: string,
    timestamp: number
) {
    return db.collection('dates').add({
        name,
        date,
        description,
        timestamp,
    });
}

export { config };

export default db;
