import App from './App';
import { FirestoreProvider } from '@react-firebase/firestore';
import React from 'react';
import ReactDOM from 'react-dom';
import { config } from './store';
import firebase from 'firebase/app';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
    <React.StrictMode>
        <FirestoreProvider firebase={firebase} {...config}>
            <App />
        </FirestoreProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
